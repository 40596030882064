/**
 * @file 404.jsx
 * @description 404 page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */


import React from "react";
import Meta from "../components/Meta";
import FullheightHero from "../components/fullheightHero";

/**
 * @function NotFoundPage
 * @description 404 page.
 * @returns {JSX.Element} 404 page.
 */

function NotFoundPage() {
  return (
    <>
      <Meta
        title="404"
        description="The page you are looking for does not exist."
        noindex={true}
      />
      <FullheightHero
        title="404"
        subtitle="The page you are looking for does not exist."
        buttonText="Go back to the homepage"
        buttonLink="/"
        color="is-danger"
      />
    </>
  );
}

export default NotFoundPage;
