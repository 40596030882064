/**
 * @file meta.jsx
 * @description Meta component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */


import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

/**
 * @function Meta
 * @description This component is used to set the meta data of the page.
 * @param {Object} props
 * @param {string} [props.title]
 * @param {string} [props.description]
 * @param {string} [props.image]
 * @param {string} [props.url]
 * @param {boolean} [props.noindex]
 * @returns {JSX.Element} Meta component.
 * @example <Meta title="Flatchine" description="A bright style with flat colors from the Chinese-Color-Palette."/>
 */


function Meta(props) {
  const location = useLocation();
  const title =
    props.title + " - Flatchine - Felix | D1strict" ||
    "Flatchine - Felix | D1strict";
  const description =
    props.description ||
    "A bright style with flat colors from the Chinese-Color-Palette.";
  const image = props.image || "https://flatchine.felix-d1strict.de/images/social_flatchine-min.png";
  const url =
    props.url || "https://flatchine.felix-d1strict.de" + location.pathname;

  return (
    <Helmet>
      <title>{title}</title>
      {props.noindex && <meta name="robots" content="noindex" />}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/images/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/images/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/images/android-chrome-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/images/favicon-16x16.png"
      />
      <link rel="manifest" href="/images/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/images/safari-pinned-tab.svg"
        color="#00459e"
      />
      <link rel="shortcut icon" href="/images/favicon.ico" />
      <meta
        name="apple-mobile-web-app-title"
        content="Flatchine by Felix | D1strict"
      />
      <meta name="application-name" content="Flatchine by Felix | D1strict" />
      <meta name="msapplication-TileColor" content="#00459e" />
      <meta
        name="msapplication-TileImage"
        content="/images/mstile-144x144.png"
      />
      <meta name="msapplication-config" content="/images/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff"></meta>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
}

export default Meta;
