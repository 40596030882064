/**
 * @file impressions.jsx
 * @description Impressions component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Client, Storage } from "appwrite";

/**
 * @function Impressions
 * @throws {Error} If API endpoint or project ID is not set or if the API call fails.
 * @returns {JSX.Element} Impressions component.
 */

function Impressions() {
  const client = new Client();
  const storage = new Storage(client);

  client
    .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT) // Your API Endpoint
    .setProject(process.env.REACT_APP_APPWRITE_PROJECT);

  const images = [
    {
      fileID: "screenshot_1",
      alt: "A screenshot shows the forum list in the “Flatbrit”-Design.",
      text: "Flat-Colors Chinese color palette hosts great and appealing colors perfected for a website. “Flatchine” brings these colors together and mixes them to create a wonderful, bright WoltLab Suite™ design for the beloved community.",
    },
    {
      fileID: "screenshot_2",
      alt: "A screenshot shows a forum theme in the “Flatbrit”-Design.",
      text: "Slight customizations of the “Flatchine” style are super easy and don't require any knowledge of graphic design. Despite the gradients of some edges created by a CSS trick, the colors are harmonious to the eyes and can be adjusted to your own community in no time.",
    },
  ];

  return (
    <div className="section">
      <h2 className="title is-2 has-text-centered">Impressions</h2>
      <h3 className="subtitle is-4 has-text-centered">
        A few impressions of the “Flatchine”-Design.
      </h3>
      <br />
      <div className="columns">
        {images.map((image) => (
          <div className="column" key={image.fileID}>
            <div className="card">
              <div className="card-image">
                <a
                  href={
                    "https://api.felix-d1strict.de/v1/storage/buckets/63f0c0e4872cc6baac4a/files/" +
                    image.fileID +
                    "/view?project=63cd3e953da71c7c7b80"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <figure className="image is-4by3">
                    <img
                      src={storage.getFilePreview(
                        "63f0c0e4872cc6baac4a",
                        image.fileID,
                        800,
                        600,
                        "center",
                        70,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        "webp",
                      )}
                      alt={image.alt ? image.alt : ""}
                      width="800"
                      height="600"
                      loading="lazy"
                    />
                  </figure>
                </a>
              </div>
              {image.text && (
                <div className="card-content">
                  <div className="content">{image.text}</div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Impressions;
