/**
 * @file license.jsx
 * @description License page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import Hero from "../components/hero";

/**
 * @function LicensePage
 * @returns {JSX.Element} License page.
 */

function LicensePage() {
  return (
    <>
      <Meta
        title="License"
        description="Flatchine is licensed under the Free Products - License by Felix | D1strict."
        noindex={true}
      />
      <Hero
        title="License"
        subtitle="Flatchine is licensed under the Free Products - License by Felix | D1strict."
      />
      <div className="section">
        <div className="container">
          <div className="content">
            <h3 className="title is-3">§ 1 Scope</h3>
            <ol>
              <li>
                The following license terms apply to all free products of the
                manufacturer Felix Waßmuth, trading under “Felix | D1strict”,
                “D1strict” and/or “D1strict-Development”. The products are
                distributed either directly on the manufacturer's site at
                https://felix-d1strict.de/ (and its subpages & subdomains) or in
                the plugin store of WoltLab GmbH.
              </li>
              <li>
                The terms of this license agreement apply exclusively. Verbal
                side agreements or any terms and conditions of the buyer are
                already now contradicted.
              </li>
              <li>
                By downloading the product, you agree to the following license
                terms.
              </li>
              <li>
                Your right to use the product automatically expires if any of
                the terms of this license agreement are violated. Upon
                termination of the right to use the Product, you shall delete
                the Product from the servers and databases used and destroy all
                copies of the Product, including the documentation provided with
                the Product and any modified and altered versions of the
                Product.
              </li>
            </ol>
            <h3 className="title is-3">§ 2 Rights of use</h3>
            <ol>
              <li>
                The license for the software is unlimited in space and time.
              </li>
              <li>
                The sole right of use lies exclusively with the license holder.
              </li>
              <li>
                The license holder is entitled to use the product on an
                unlimited number of websites.
              </li>
              <li>
                Copyright notices and other features serving the software and
                source code identification may not be removed or changed. They
                are to be transferred to each copy of the software. This does
                not apply if you have purchased a “Branding Free” license from
                the manufacturer, which entitles you to remove the corresponding
                copyright and other proprietary notices.
              </li>
              <li>
                You may use the Software for commercial purposes, unless,
                otherwise expressly provided.
              </li>
              <li>
                You may not decompile, modify, or edit the Software beyond what
                is permitted by law, i.e., unless necessary to interface with
                other software products or to correct errors in the Software.
                However, you may modify, edit the source code of the Software as
                well as the corresponding database structure of the Software
                allocated by tables at your own risk in order to adapt it to
                your needs, both graphically and functionally.
              </li>
              <li>
                The license holder is not entitled to transfer the license to
                third parties.
              </li>
            </ol>
            <h3 className="title is-3">§ 3 Sharing</h3>
            <ol>
              <li>
                The license holder is not entitled to share the product with
                third parties.
              </li>
              <li>
                {" "}
                The source code and/or files of the Software may not be resold
                or distributed.
              </li>
            </ol>
            <h3 className="title is-3">§ 4 Copyright</h3>
            <ol>
              <li>
                The copyrights for the graphics and scripts created by the
                manufacturer remain solely with Felix Waßmuth, unless otherwise
                stated.
              </li>
            </ol>
            <h3 className="title is-3">§ 5 Warranty and liability</h3>
            <ol>
              <li>
                The manufacturer accepts no liability for any damage, errors,
                loss of data or the like that may arise from the use of the
                product.
              </li>
            </ol>
            <h3 className="title is-3">§ 6 Final provisions</h3>
            <ol>
              <li>
                The manufacturer reserves the right to change the license terms
                at any time. The license holder will be informed of any changes
                to the license terms by his website.
              </li>
              <li>
                The license agreement is subject to German law. The place of
                jurisdiction is Berlin.
              </li>
              <li>
                If individual provisions of this contract are invalid or
                unenforceable or become invalid or unenforceable after
                conclusion of the contract, the validity of the remainder of the
                contract shall not be affected. The invalid or unenforceable
                provision shall be replaced by a valid and enforceable
                provision, the effects of which most closely approximate the
                economic objective pursued by the contracting parties with the
                invalid or unenforceable provision. The above provisions shall
                apply mutatis mutandis in the event that the contract proves to
                be incomplete.
              </li>
            </ol>
            <p className="has-text-right">
              <small>Last updated: 2023-02-11</small>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default LicensePage;
